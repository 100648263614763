/* eslint-disable indent */
import React, { useRef, useState, useEffect } from "react";
import InspectionFilters from "../../components/inspection-filters";
import Header from "../../components/header";
import Search from "../../components/shared/search";
import styles from "./inspection.module.css";
import InfiniteScroll from 'react-infinite-scroller';
import Button from "../../components/shared/button/component";
import { RESET_REDUCER_STATE_KEYS, LOCAL_STORAGE_KEYS, INSPECTION_STATUS_KEYS, ROLE_KEYS } from "../../utils/app-constants";
import firebase from "../../utils/firebase";
import {
  getFromLocalStorage,
  hasTimeElapsed,
  getTimeDifferenceFromCurrentTime,
  reloadWithDelay,
  isExcludedUser
} from "../../utils/helper";

import Timer from "react-compound-timer";
import ColorPallet from "../../components/colorpallet";
import moment from "moment";
import { InspectionService } from "../../service";
import {
  getFireBaseDB
} from "../../utils/url-constants";
import DataInformationMessage from "../../components/data-information-message";

const Inspection = ({ inspectionsReducer, fetcInspectionListConnect, inspectionFiltersReducer,
  resetInspectionByKeyConnect, history, search, AssignToMeConnect, resetInspectionDetailByKeyConnect, match }) => {

  const scrollRef = useRef();
  const [pageStart] = useState(0);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const { data, total, isLoading, error, initialCall } = inspectionsReducer;
  const { citySelected, locationSelected, categorySelected, } = inspectionFiltersReducer;
  let { assigneeSelected } = inspectionFiltersReducer;
  const { query } = search;
  const [colorCode, setColorCode] = useState("Red");
  const [excludedUser, setExcludedUser] = useState({});
   const [sleepTime, setSleeptime] = useState({});
  const role = getFromLocalStorage(LOCAL_STORAGE_KEYS.ROLE);
  // const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
  // const email = localData ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.EMAIL] : "";
  const email = getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL);
  // const [initialCall,setInitialCall] = useState(true);
  let isUserExludedUser = isExcludedUser();

  // const excludedData = getFromLocalStorage(LOCAL_STORAGE_KEYS.EXCLUDED_USER);
  // const excludedJson = excludedData ? JSON.parse(excludedData) : {};

  // const sleeptimeData = getFromLocalStorage(LOCAL_STORAGE_KEYS.SLEEP_TIME);
  // const sleeptimeJson = sleeptimeData ? JSON.parse(sleeptimeData) : {};

  useEffect(() => {
    // InspectionService.getExcludedList().then(response => {
    //   const { data: { detail } } = response;
    //   setExcludedUser(detail);
    // }).catch(error => {
    // });
    InspectionService.getSleeptimeConfig().then(response => {
       const { data: { detail: { data } } } = response;
       setSleeptime(data);
     }).catch(error => {
    });
    try {
      var firebaseDatabase = firebase.getInstance().firestore();
    // console.log(firebaseDatabase)
    firebaseDatabase.collection("qc-auto-assignment").where("email", "==", email)
		.onSnapshot((snapshot) => {
			snapshot.docChanges().forEach((change) => {
				if (change.type === "added" && change.doc.data().status == "changed") {
					firebaseDatabase.collection("qc-auto-assignment").doc(email).update({
							status: "pending"
						});
						reloadWithDelay(500);
				}
				if (change.type === "modified" && change.doc.data().status == "changed") {
					console.log("Modified city: ", change.doc);
					
					// Set the "capital" field of the city 'DC'
						firebaseDatabase.collection("qc-auto-assignment").doc(email).update({
							status: "pending"
						});
						reloadWithDelay(500);
				}
			});
		});
    } catch(error) {
      console.log('error-->>',error);
    }

    // var database = firebase.getInstance().database();
    // const inspectionDb = firebase.getInstance().database().ref(`/${getFireBaseDB()}`);
    // inspectionDb.on('child_added', (snapshot) => {
    //   const data = snapshot.val();
    //   const key = snapshot.key;
    //   if (data) {
    //     if (data.email == email && data.status == 'changed') {
    //       database.ref(`/${getFireBaseDB()}/` + key).update({ 'status': 'pending' });
    //       reloadWithDelay(0);
    //       // loadMoreInspections(1, citySelected, locationSelected, categorySelected, assigneeSelected);
    //     }
    //   }
    // });
    // inspectionDb.on('child_changed', (snapshot) => {
    //   const data = snapshot.val();
    //   const key = snapshot.key;
    //   if (data) {
    //     if (data.email == email && data.status == 'changed') {
    //       database.ref(`/${getFireBaseDB()}/` + key).update({ 'status': 'pending' });
    //       reloadWithDelay(0);
    //       // loadMoreInspections(1, citySelected, locationSelected, categorySelected, assigneeSelected);
    //     }
    //   }
    // });

    return () => {
      // inspectionDb.off();
      resetAndFetchNewListing();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!!query && query !== "") {
      resetAndFetchNewListing();
      if (query === "-1") {
        // loadMoreInspections(1, citySelected, locationSelected, categorySelected, assigneeSelected);
      }
      else {
        loadMoreInspections(1, [], [], "", "", query);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);
  useEffect(() => {
    if (!!citySelected && citySelected.length > 0) {
      resetAndFetchNewListing();
      loadMoreInspections(1, citySelected, locationSelected, categorySelected, assigneeSelected);
    }
    else if (citySelected.length === 0 && !initialCall) {
      resetAndFetchNewListing();
      loadMoreInspections(1, [], locationSelected, categorySelected, assigneeSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(citySelected)]);
  useEffect(() => {
    if (!!locationSelected && locationSelected && !isLoading) {
      resetAndFetchNewListing();
      loadMoreInspections(1, citySelected, locationSelected, categorySelected, assigneeSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(locationSelected)]);
  useEffect(() => {
    if (!!assigneeSelected && assigneeSelected !== "") {
      resetAndFetchNewListing();
      loadMoreInspections(1, citySelected, locationSelected, categorySelected, assigneeSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assigneeSelected]);
  useEffect(() => {
    if (!!categorySelected && categorySelected !== "") {
      resetAndFetchNewListing();
      loadMoreInspections(1, citySelected, locationSelected, categorySelected, assigneeSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorySelected]);

  const loadMoreInspections = ((page, storeCityId, storeId, category, assignedToSearch, appointmentId) => {
    // fetcInspectionListConnect(page);
    setHasMoreItems(false);
    const startDate = moment(moment(new Date()).subtract(6, 'months').toDate()).format('YYYY-MM-DD').toString();
    const endDate = moment(moment(new Date()).toDate()).format('YYYY-MM-DD').toString();
    if (!isUserExludedUser) {
      let userUuid =  JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA) || "{}")?.id;
      assignedToSearch = userUuid;
      if (!!assignedToSearch) {
        assigneeSelected = assignedToSearch;
      }
    }
    if (((citySelected && storeCityId) || (locationSelected.length > 0 && storeId) || assigneeSelected || categorySelected)) {
      fetcInspectionListConnect(page, email, null, citySelected, locationSelected, categorySelected, assigneeSelected, appointmentId, startDate, endDate).then(resp => {
        if (resp.data) {
          const { data: { status, detail } } = resp;
          //
          if (detail && detail.length === 50) {
            setHasMoreItems(true);
          }
        }

      }).catch(error => {
        const { status, detail } = error;
        if (!!status && status === 403) {
          localStorage.clear();
          window.location = `${window.origin}/login`;
        }
      });
    }
    else {
      fetcInspectionListConnect(page, email, null, storeCityId, storeId, category, assignedToSearch, appointmentId, startDate, endDate).then(resp => {
        if (resp.data) {
          const { data: { status, detail } } = resp;
          //
          if (detail && detail.length === 50) {
            setHasMoreItems(true);
          }
        }

      }).catch(error => {
        const { status, detail } = error;
        if (!!status && status === 403) {
          localStorage.clear();
          window.location = `${window.origin}/login`;
        }
      });
    }
    resetInspectionByKeyConnect("initialCall", false);
  });
  const resetAndFetchNewListing = () => {
    scrollRef.current.pageLoaded = 0;
    setHasMoreItems(true);
    resetInspectionByKeyConnect(RESET_REDUCER_STATE_KEYS.DATA, []);
    resetInspectionByKeyConnect("isLoading", true);
  };

  const convertTimestamptoTime = (unixTimestamp) => {
    const dateObj = new Date(unixTimestamp * 1000);
    const utcString = dateObj.toUTCString();
    const time = utcString.slice(-11, -7);
    return time;
  };
  const getAssignToMe = (carId, item) => {
   
    if (isUserExludedUser || (sleepTime.sleepTime == 0 && role === ROLE_KEYS.QC ) ) {
      return (
        // <input type="Button" onClick={() => assignToMeClick()}></input >
        <Button classNames="assignCta" ctaText={"assign to me"} onClick={(e) => assignToMeClick(e, carId, item)} />
      );
    }
  };
  const assignToMeClick = (e, carId, item) => {

    e.stopPropagation();
    const { isReportProcessed, reInspectionProcessing, dealerPriceProcessing } = item;
    if (isReportProcessed === 0 || reInspectionProcessing === 1 || dealerPriceProcessing === 1) {
      alert("Report is under process.");
    }
    else {
      const checkin_data = {
        "role": "qc",
        "inspectionStatus": "smReview",
        "user": email,
        "qcAssignTo": email
      };
      AssignToMeConnect(carId, checkin_data).then(response => {
        resetInspectionDetailByKeyConnect("isLoading", true);
        history.push(`review/${carId}`);
        //Appointment 1024169699 is already assigned to vivek.tripathi@cars24.com”
      }).catch(error => {
      });
    }
  };
  const redirectToReview = (item) => {
    const { isReportProcessed, reInspectionProcessing, dealerPriceProcessing } = item;
    if (isReportProcessed === 0 || reInspectionProcessing === 1 || dealerPriceProcessing === 1) {
      alert("Report is under process.");
    }
    else {
      resetInspectionDetailByKeyConnect("isLoading", true);
      history.push(`review/${item.carId}`);
    }
  };
  const getStatusCategoryCell = (item) => {
    let pdiType = item?.inspectionData?.carDetails?.pdiType?.value;
    let newPdiFlag = pdiType == "New car" ? true : false;
    return (<div className={styles.categoryCell}>{item.category}
    {item?.inspectionStatus === INSPECTION_STATUS_KEYS.DONE && <p className={styles.done}>Doc Upload Verfication</p>}
    {item?.isPDILead ? <p className={styles.done}>{newPdiFlag ? 'N' : null}PDI Lead</p> : null}
    {item?.workflowId ? <p className={styles.done}>Inspected By New App</p> : null}
    </div>);
  };
  const renderInspectionList = (itemList) => {
    return (
      itemList.map(item =>
        (
          <tr onClick={() => redirectToReview(item)}>
            {/* <td><p className={styles.redcolor}></p></td> */}
            <td><ColorPallet appointmentId={item.appointmentId} initialTime={item.inspectionEndTime} /></td>
            <td >{item.appointmentId}</td>
            <td>{getStatusCategoryCell(item)}</td>
            <td>{item.inspectionEndTime}</td>
            <td>{item.make}/{item.model}</td>
            <td>{item.storeName}</td>
            <td>{(!!item.qcAssign && !!item.qcAssign.to) ? item.qcAssign.to : getAssignToMe(item.carId, item)}</td>
            {item.qcAudit && <td><p className={styles.qcAudit}>QC Audit <span> Required</span></p></td>}
            {item.reInspectionReason && item.reInspectionReason.reason && 
            item.reInspectionReason.reason.toLowerCase() === "ri after refurbishment" && 
            <td className={styles.riAfterRefurb}>{item.reInspectionReason.reason}</td>}
          </tr>
        )
      )
    );
  };
  return (
    <div>
      <Header history={history} showVersion={true} />
      <div className={styles.inspectionHeader}>
        <InspectionFilters />
        <Search />
      </div>
      {data && data.length === 0 && !isLoading && <p className={styles.noAppointment}>No Appointment Data</p>}
      <div className={styles.inspectionList}>
        <InfiniteScroll
          key={"1"}
          ref={scrollRef}
          className={styles.inspectionList}
          pageStart={pageStart}
          loadMore={loadMoreInspections}
          hasMore={hasMoreItems}
        >
          {data && data.length > 0 && !isLoading &&
            <table cellPadding="0" cellSpacing="0" width="100%" >
              <thead>
                <tr>
                  <th></th>
                  <th>Appt ID <span className={styles.count}>({total})</span></th>
                  <th>Category</th>
                  <th>Inspection End Time</th>
                  <th>Car</th>
                  <th>Centre</th>
                  <th>Assignee</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {renderInspectionList(data)}
              </tbody>
            </table >
          }
        </InfiniteScroll>
      </div>
      <DataInformationMessage
        isLoading={isLoading}
        data={[1]}
        error={error}
      />

    </div>

  );
};

export default Inspection;
