import React, { useEffect, useState } from 'react';
import Carousel from '../../components/carousel';
import H2 from '../../components/h2';
import InspectionCheckBox from '../../components/inspection-check-box';
import ImageCheckbox from '../../components/image-checkbox/';
import styles from './inspection-review-insurance.module.css';
import InspectionInsurance from '../inspection-insurance';
import {
  INSPECTION_SECTION,
  LOCAL_STORAGE_KEYS,
  RESET_REDUCER_KEYS,
} from '../../utils/app-constants';
import InspectionMismatch from '../inspection-mismatch';
import InspectionOtherDetails from '../inspection-other-details';
import Remark from '../shared/remark/Remark';
import { getFromLocalStorage } from '../../utils/helper';

const InspectionReviewInsurance = ({
  inspectionDetails,
  resetInspectionDetailByKeyConnect,
  fetchInspectionRestrictImagesConnect,
}) => {
  const [insuranceType, setInsuranceType] = useState(null);
  const [noClaimPer, setNoClaimPer] = useState(null);
  const {
    insuranceMapper,
    showMismatch,
    mismatchMapper,
    otherDetailsMapper,
    insuranceImageMapper,
    carDetails,
    qcAudit,
    parivahanWebsite,
    isSpecialFieldEditable,
    carId,
    appointmentId,
    leadToken
  } = inspectionDetails;
  const [showMismatchBool, setShowMismatchBool] = useState(showMismatch);
  const [currentIndex, setCurrentIndex] = useState(0);
  const existingMismatch =
    carDetails.insuranceType.subParts &&
    carDetails.insuranceType.subParts.mismatchInInsurance
      ? carDetails.insuranceType.subParts.mismatchInInsurance.value.split(',')
      : [];
  const { insuranceExpiry } = parivahanWebsite || {};
  const onFetchingRestrictImages = () => {
    fetchInspectionRestrictImagesConnect(appointmentId, leadToken);
  };
  const isIsuranceDateValid = () => {
    const newDate = new Date();
    const insuranceExpiryDate = new Date(insuranceExpiry);
    return insuranceExpiryDate.getTime() >= newDate.getTime();
  };

  const showQcAuditLabel = () => {
    return (
      (qcAudit &&
        insuranceType &&
        insuranceType.value === 'Insurance Expired' &&
        isIsuranceDateValid()) ||
      false
    );
  };

  useEffect(() => {
    let arr = [];
    mismatchMapper.map((mismatch, index) => {
      if (
        existingMismatch &&
        existingMismatch.find(
          item =>
            item.toLowerCase().trim() === mismatch.text.toLowerCase().trim(),
        )
      ) {
        mismatchMapper[index].isImportant = true;
        mismatchMapper[index].checked = true;
        arr.push(mismatch.text.trim());
      }
    });
    // if(arr.find(item=>item.match(/mismatch/i))){
    //     mismatchMapper[0].checked = true;
    //     mismatchMapper[0].isImportant = true;
    // }
    resetInspectionDetailByKeyConnect('commentsMapper', mismatchMapper);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowMismatchBool(showMismatch);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showMismatch]);

  useEffect(() => {
    let mapper = [...insuranceMapper];
    mapper = mapper.map(map => {
      let element = { ...map };
      if (element.key === 'noClaimBonuspercent') {
        if (noClaimPer === 'Yes') {
          element.isVisible = true;
          element.isDisabled = true;
          element.checked = true;
        } else {
          element.isVisible = false;
          element.checked = false;
          element.isDisabled = false;
          element.newValue = '';
          element.newId = '';
        }
      }
      return element;
    });
    resetInspectionDetailByKeyConnect(
      RESET_REDUCER_KEYS.INSURANCE_MAPPER,
      mapper,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noClaimPer]);

  useEffect(() => {
    if (insuranceMapper.length > 0) {
      const initialValue = insuranceMapper.find(
        item => item.key === 'insuranceType',
      );
      if (JSON.stringify(initialValue) !== JSON.stringify(insuranceType)) {
        setInsuranceType(initialValue);
      }
      const noClaimBonusValue = insuranceMapper.find(
        item => item.key === 'noClaimBonus',
      );
      const currentValue = noClaimBonusValue.newValue
        ? noClaimBonusValue.newValue
        : noClaimBonusValue.value;
      setNoClaimPer(currentValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(insuranceMapper)]);

  useEffect(() => {
    if (insuranceMapper.length > 0 && insuranceType) {
      const currentValue = insuranceType.newValue
        ? insuranceType.newValue
        : insuranceType.value;
      //cases
      let dependentFields = [];
      if (
        currentValue === 'Comprehensive' ||
        currentValue === 'Zero Depreciation'
      ) {
        dependentFields = ['insuranceExpiry', 'noClaimBonus'];
        resetInspectionDetailByKeyConnect(
          RESET_REDUCER_KEYS.SHOW_MISMATCH,
          true,
        );
      } else if (currentValue === '3rd Party') {
        dependentFields = ['insuranceExpiry'];
        resetInspectionDetailByKeyConnect(
          RESET_REDUCER_KEYS.SHOW_MISMATCH,
          true,
        );
      } else if (currentValue === 'Insurance Expired') {
        dependentFields = [];
        resetInspectionDetailByKeyConnect(
          RESET_REDUCER_KEYS.SHOW_MISMATCH,
          false,
        );
      }
      if (currentValue !== 'Insurance Expired' && currentValue !== '3rd Party' && noClaimPer === 'Yes') {
        dependentFields.push('noClaimBonuspercent');
      }
      //updating
      let mapper = [...insuranceMapper];

      mapper = mapper.map(map => {
        let element = { ...map };
        if (dependentFields.find(field => field === element.key)) {
          element.isVisible = true;
          if (insuranceType.newValue && insuranceType.checked) {
            element.isDisabled = true;
            element.checked = true;
            element.newValue = element.newValue ? element.newValue : element.value;
            element.newId = element.newId ? element.newId : element.id;
          } else if (!insuranceType.checked) {
            element.isDisabled = false;
            element.checked = false;
            element.newValue = '';
            element.newId = '';
          }
          element.hideRemarkInput = element.checked;
        } else if (element.key !== 'insuranceType') {
          element.isVisible = false;
          element.checked = false;
          element.newValue = '';
          element.newId = '';
          element.isDisabled = false;
        }

        return element;
      });
      resetInspectionDetailByKeyConnect(
        RESET_REDUCER_KEYS.INSURANCE_MAPPER,
        mapper,
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insuranceType]);

  const insuranceImageCount = insuranceImageMapper.filter(image => image.url)
    .length;
  return (
    <div className={styles.carouselTextWrapper}>
      <div className={styles.carousel}>
        {insuranceImageMapper.length > 0 && (
          <>
            <Carousel
              images={insuranceImageMapper}
              key={'insuranceImage'}
              setCurrentIndex={setCurrentIndex}
              height={630}
              wheelZoomDisabled={false}
              currentMapper="insuranceImageMapper"
              insuranceImageCount={insuranceImageCount}
              showInsuranceCount={true}
              fetchRestrictImagesHandler={onFetchingRestrictImages}
              addGAEvents={(imgUrl) => ({
                event:'right_click',
                eventCategory:'insp_qc_panel',
                eventAction:'documents',
                eventLabel:`${appointmentId}|${getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL)}|${imgUrl}`,
              })}
              hasGAEvent={true}
            />
            <ImageCheckbox
              index={currentIndex}
              currentMapper="insuranceImageMapper"
              section={INSPECTION_SECTION.CAR_DETAILS}
            />
          </>
        )}
      </div>
      <div className={styles.carouselContent}>
        <div className={styles.rcTextWrapper}>
          <div className={showQcAuditLabel() ? styles.qcRequiredWrapper : ''}>
            <H2 text={'Insurance'} />
            {showQcAuditLabel() && (
              <p className={styles.qcRequired}>
                QC Audit <span>Required</span>
              </p>
            )}
          </div>

          {insuranceMapper.map(document => {
            if (document.text && document.isVisible)
              return <InspectionInsurance document={document} />;
          })}
          {showQcAuditLabel() && (
            <p className={styles.qcAuditInstruction}>
              As per Vaahan, Insurance is available and is valid till{' '}
              {insuranceExpiry}{' '}
            </p>
          )}
          {showMismatchBool && (
            <div className={styles.infoSection}>
              <H2 text={'Mismatch'} />
              <div className={styles.misMatch}>
                {mismatchMapper.map(mismatch => {
                  if (!mismatch.isVisible)
                    return <InspectionMismatch document={mismatch} />;
                })}
              </div>
              <div className={styles.misMatchSection}>
                <ul>
                  {mismatchMapper.map(mismatch => {
                    if (mismatch.isVisible)
                      return <InspectionMismatch document={mismatch} />;
                  })}
                </ul>
                <Remark
                  document={mismatchMapper[0]}
                  section={INSPECTION_SECTION.CAR_DETAILS}
                  showRemarkInput={
                    !!mismatchMapper.find(obj => obj.checked == true)
                  }
                  isRemarkHistoryOpen={true}
                />
              </div>
            </div>
          )}

          {otherDetailsMapper.length > 0 && (
            <div className={styles.infoSection}>
              <H2 text={'Other Details'} />
              <div className={styles.misMatchSection}>
                <ul>
                  {otherDetailsMapper.map(otherDetail => {
                    if (otherDetail.key === 'ra' && !otherDetail.value.length) {
                      return null;
                    }
                    return <InspectionOtherDetails document={otherDetail} />;
                  })}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InspectionReviewInsurance;
