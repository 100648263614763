import React, { useState } from "react";
import InspectionHeaderBack from "../../components/inspection-header-back/component";
import styles from "./inspection-header.module.css";
import Button from "../shared/button/component";
import { getFromLocalStorage, getVersionText, isAssignedToMe, isUserRoleBIkeQc, isExcludedUser, isAuthorizedAccess, getHomepageURL } from "../../utils/helper";
import { LOCAL_STORAGE_KEYS, INSPECTION_STATUS_KEYS, CAR_ID_REGEX } from "../../utils/app-constants";
import { ToastContainer } from 'react-toastify';
import InspectionHeaderTimer from "../../components/inspection-header-timer";
import { InspectionService } from "../../service";
import RejectionTable from "../rejection-table";
import { useRejectionTable } from "../hooks";

const InspectionHeader = ({ inspectionDetails, bikeInspectionDetails, history, AssignToMeConnect, cjName, reportUrl, pdfUrl, bikeVersion }) => {
    const [isRemarkHistoryOpen, setIsRemarkHistoryOpen] = useState(false);
    const rejectionData = useRejectionTable();
    let isBikeQc = isUserRoleBIkeQc();
    const role = getFromLocalStorage(LOCAL_STORAGE_KEYS.ROLE);
    const { appointmentId, version, carId, qcAssign, selectedVersion, rejectionRemarkMapper, reInspectionReason, inspectionStatus='', isPDILead = 0, workflowId, c2cEligibility = false} = inspectionDetails;
    const currentUser = qcAssign ? qcAssign.to : "";
    const email = getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL);
    const isUserExludedUser = isExcludedUser();
    let pathName = '/';
    if (typeof window !== 'undefined') {
        pathName = window.location.pathname;
      }
      const path = pathName.trim().replace(CAR_ID_REGEX, '');
      const access = isAuthorizedAccess(path);
      const homePagepath = getHomepageURL(role);
      if (!access) {
        history.push(homePagepath);
      }

    const assignToMeClick = (carId) => {
        const checkin_data = {
            "role": "qc",
            "inspectionStatus": "smReview",
            "user": email,
            "qcAssignTo": email
        };
        AssignToMeConnect(carId, checkin_data).then(response => {
            setTimeout(() => {
                window.location.reload();
            }, 1000);
            //Appointment 1024169699 is already assigned to vivek.tripathi@cars24.com”
        }).catch(error => {
        });
    };
    
    /**
     * Create Pdf for the Bike
     */
    const createPdf = () => {
        const {appointmentId } = bikeInspectionDetails;
        InspectionService.createPdfForBike(appointmentId)
            .then(res => {
                if(res.status === 200 && typeof window !== 'undefined') {
                    alert("Pdf created successfully!");
                    window.location.reload();
                } else {
                    alert("There is some problem on server side, please try again!");
                }            
            })
            .catch(e => {            
            });        
    };

    const handleRejectionHistoryClick = () => {
        setIsRemarkHistoryOpen(!isRemarkHistoryOpen);
    };

    const inspectionHeaderText = getVersionText(version);

    let pdiType = inspectionDetails?.carDetails?.pdiType?.value;
    let newPdiFlag = pdiType == "New car" ? true : false;

    return (
        <div className={`${styles.inspectionHeader} ${version === 0 ? styles.blueBack : isBikeQc ? styles.blueBack : styles.orangeBackground}`}>
            <div className={styles.inspectionLeft}>
                <InspectionHeaderBack history={history} versionFlag={selectedVersion > -1} isBikeQcRole={isBikeQc} />
                <p className={styles.inspectionId}>{appointmentId}</p>
                <div className={styles.inspectionType}>
                    <p>{inspectionHeaderText}</p>
                    {!!reInspectionReason && <p className={styles.reasons}>{reInspectionReason}</p>}
                </div>
                {c2cEligibility && <span className={styles.inspectionStatusDone}>C2C Eligible</span>}
                {workflowId && workflowId !== '' && <span className={styles.inspectionStatusDone}>Inspected By New App</span>}
                {inspectionStatus === INSPECTION_STATUS_KEYS.DONE && <span className={styles.inspectionStatusDone}>Doc Upload Verfication</span>}
                {isPDILead ? <span className={styles.inspectionStatusDone}>{newPdiFlag ? 'N' : null}PDI Lead</span> : null}
                {isBikeQc &&
                    <>
                        {!!cjName && <p className={styles.inspectionType}>{cjName}</p>}
                    </>
                }
            </div>
            <ToastContainer />
            {!!rejectionRemarkMapper && rejectionRemarkMapper.length > 0 &&
                <div className={styles.historyWrapper}>
                    <p>History</p>
                    <div className={styles.historyData}>
                        {rejectionRemarkMapper.map((item, index) => {
                            return (
                                <ul>
                                    <li>
                                        <p>{item.rejectionRemark}:</p>
                                        <span>({item.role}){item.timestamp}</span>
                                    </li>
                                </ul>
                            );
                        })}
                    </div>
                </div>
            }
            <div className={styles.assignTimerWrapper}>
                {isBikeQc &&
                    <>
                        {<a className={styles.viewReport} href={`/bike-report/${bikeInspectionDetails.appointmentId}?version=${bikeVersion || bikeInspectionDetails.version}`} target="_blank">View Bike Report</a>}
                        &nbsp;&nbsp;
                        {!!reportUrl && <a className={styles.viewReport} href={reportUrl} target="_blank">View Report</a>}
                    </>
                }
                {rejectionData.length > 0 && (
                    <div className={styles.historyWrapper}>
                        <p onClick={handleRejectionHistoryClick}>Rejection History</p>
                        {isRemarkHistoryOpen && (
                            <div className={styles.historyData}>
                                <RejectionTable data={rejectionData} />
                            </div>
                        )}
                    </div>
                )}
                {
                    !isBikeQc && selectedVersion < 0 && !currentUser && !isAssignedToMe(currentUser) && isUserExludedUser && <div>
                        <Button classNames="assignButton" ctaText={"assign to me"} onClick={() => {
                            assignToMeClick(carId);
                        }} />
                    </div>
                }
                <div className={styles.timerWrapper}>
                    <InspectionHeaderTimer carId={carId} isBikeQcRole={isBikeQc} />
                </div>
            </div>
        </div>
    );
};

export default InspectionHeader;
