import { createReducer } from 'reduxsauce';
import Types from './types';
import { thumborUrl } from '../../utils/url-constants';
import { saveToLocalStorage } from '../../utils/helper';
import {
  LOCAL_STORAGE_KEYS,
  RESET_REDUCER_KEYS,
  INSPECTION_STATUS_KEYS,
} from '../../utils/app-constants';
import { isAssignedToMe } from '../../utils/helper';
import {
  getDocumentsMapper,
  getInsuranceMapper,
  getMismatchMapper,
  insuranceOtherDetailsMapper,
  getMakeModelVariantMapper,
  getCarDetailsOdometerMapper,
  getCarDetailsDuplicateMapper,
  getRcImagesMapper,
  getExteriorTyresImagesMapper,
  getInteriorImagesMapper,
  getInsuranceImageMapper,
  getInteriorDataMapper,
  getExteriorDataMapper,
  getAirConditoningDataMapper,
  getTyreMapper,
  getTimeStringFromSeconds,
  getExteriorComments,
  getAdditionalImagesMapper,
  getElectricalInteriorComments,
  getInteriorElecctricalsMapper,
  getRejectionRemarkMapper,
  getImperfectionDataMapper
} from '../../utils/helper';

export const INITIAL_STATE = {
  isLoading: true,
  carDetails: null,
  columns: [],
  carId: '',
  leadToken: '',
  appointmentId: '',
  inspectionStatus: '',
  page: 0,
  version: '',
  documentsMapper: [],
  insuranceMapper: [],
  mismatchMapper: [],
  makeModelVariantMapper: [],
  otherDetailsMapper: [],
  commentsMapper: [],
  odometerMapper: [],
  duplicateKeyMapper: [],
  interiorMapper: [],
  error: null,
  total: 0,
  showMismatch: false,
  rcImagesMapper: [],
  exteriorTyresMapper: [],
  interiorImagesMapper: [],
  insuranceImageMapper: [],
  interiorDataMapper: [],
  externalDataMapper: [],
  airConditioningDataMapper: [],
  lhsFrontTyremapper: [],
  rhsFrontTyremapper: [],
  lhsRearTyremapper: [],
  spareTypremapper: [],
  rhsRearTyremapper: [],
  reInspection: [],
  exteriorComments: null,
  qcAssign: null,
  riTableData: [],
  currentAuctionVersion: '',
  lastAuctionVersion: '',
  inspectionBy: '',
  isCritical: 0,
  mapperCounts: null,
  rejectionRemark: '',
  issueType: [],
  raEmail: '',
  regionDepartmentEmail: '',
  interiorOdometerMapper: [],
  additionalImagesMapper: [],
  odometerComments: [],
  selectedVersion: -1,
  parivahanWebsite: null,
  vahanWebsite: null,
  vahanSmsL: null,
  interiorElecctricalsMapper: [],
  rejectionRemarkMapper: [],
  noValidRiBid: false,
  imagequalityParam: '',
  imageHostUrl: thumborUrl(),
  isDocumentPopup: false,
  isSpecialFieldEditable: false,
  preInspection: { preInsuranceImages: [], preRcImages: [], error: null },
  isRestrictedImage: false,
};

export const fetchInspectionDetail = (state = INITIAL_STATE) => {
  return { ...state, isLoading: true };
};

export const fetchInspectionDataStart = (state = INITIAL_STATE) => {
  return { ...state, isLoading: false };
};
export const fetchInspectionDetailEnd = (state = INITIAL_STATE) => {
  return { ...state, isLoading: false };
};

export const resetInspectionDetail = () => {
  return INITIAL_STATE;
};

export const fetchInspectionDetailSuccess = (
  state = INITIAL_STATE,
  { data },
) => {
  const {
    inspectionData: {
      carDetails,
      electricalsInterior,
      airConditioning,
      engineTransmission,
      exteriorTyres,
      steeringSuspensionBrakes,
      summary,
    },
  } = data;
  const {
    c2cEligibility,
    appointmentId,
    carId,
    leadToken,
    version,
    qcAssign,
    qcAudit,
    inspectionBy,
    inspectionStatus,
    imageResizingQueryString,
    imageHostUrl,
    isPDILead,
    videoHostUrl,
    reInspectionReason,
    vahanData,
    delayAuction: { isDocUploadedByCX = 0 } = {},
    workflowId,
  } = data;

  try {
    let imageQuality = !!imageResizingQueryString
      ? imageResizingQueryString
      : '';
    saveToLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_QUALITY_PARAM, imageQuality);

    saveToLocalStorage(LOCAL_STORAGE_KEYS.VIDEO_HOST_URL, videoHostUrl || '');

    let dynamicImageHostUrl = !!imageHostUrl ? imageHostUrl : thumborUrl();
    saveToLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_HOST_URL, dynamicImageHostUrl);

    let email = '';
    const {
      inspectionData: {
        carDetails: { raDetails },
      },
    } = data;
    if (raDetails) {
      email = raDetails.email;
    }
    // new changes for INSPECTION-674
    const { to: currentUser = '' } = qcAssign || {};
    const isPopupAllowed =
      isAssignedToMe(currentUser) &&
      [INSPECTION_STATUS_KEYS.DONE, INSPECTION_STATUS_KEYS.SM_REVIEW].includes(
        inspectionStatus,
      ) &&
      !!isDocUploadedByCX;
    const isSpecialFieldEditable =
      isAssignedToMe(currentUser) &&
      inspectionStatus === INSPECTION_STATUS_KEYS.DONE &&
      !!isDocUploadedByCX;
    const documentsMapper = getDocumentsMapper(data);
    const insuranceMapper = getInsuranceMapper(data);
    const mismatchMapper = getMismatchMapper(data);
    const otherDetailsMapper = insuranceOtherDetailsMapper(data);
    const odometerMapper = getCarDetailsOdometerMapper(data);
    const duplicateKeyMapper = getCarDetailsDuplicateMapper(data);
    const interiorOdometerMapper = getCarDetailsOdometerMapper(data);
    const odometerComments = getElectricalInteriorComments(data);

    const interiorElecctricalsMapper = getInteriorElecctricalsMapper(data);
    const rejectionRemarkMapper = getRejectionRemarkMapper(data);
    const interiorDataMapper = getInteriorDataMapper(data);
    const externalDataMapper = getExteriorDataMapper(data);
    const airConditioningDataMapper = getAirConditoningDataMapper(data);

    const makeModelVariantMapper = getMakeModelVariantMapper(data);

    const rcImagesMapper = getRcImagesMapper(data);
    const insuranceImageMapper = getInsuranceImageMapper(data);
    const exteriorTyresMapper = getExteriorTyresImagesMapper(data);
    const interiorImagesMapper = getInteriorImagesMapper(data);
    const exteriorComments = getExteriorComments(data);

    const lhsFrontTyremapper = getTyreMapper(
      data,
      'lhsFrontTyre',
      'lhsFrontTyremapper',
    );
    const rhsFrontTyremapper = getTyreMapper(
      data,
      'rhsFrontTyre',
      'rhsFrontTyremapper',
    );
    const lhsRearTyremapper = getTyreMapper(
      data,
      'lhsRearTyre',
      'lhsRearTyremapper',
    );
    const rhsRearTyremapper = getTyreMapper(
      data,
      'rhsRearTyre',
      'rhsRearTyremapper',
    );
    const spareTypremapper = getTyreMapper(
      data,
      'spareTyre',
      'spareTypremapper',
    );

    const additionalImagesMapper = getAdditionalImagesMapper(data);

    return {
      ...state,
      carDetails,
      electricalsInterior,
      airConditioning,
      engineTransmission,
      exteriorTyres,
      steeringSuspensionBrakes,
      summary,
      appointmentId,
      inspectionStatus,
      isPDILead,
      raEmail: email,
      carId,
      leadToken,
      version,
      inspectionBy,
      qcAssign,
      qcAudit,
      documentsMapper,
      insuranceMapper,
      mismatchMapper,
      otherDetailsMapper,
      odometerMapper,
      duplicateKeyMapper,
      interiorOdometerMapper,
      makeModelVariantMapper,
      rcImagesMapper,
      exteriorTyresMapper,
      interiorImagesMapper,
      insuranceImageMapper,
      interiorDataMapper,
      externalDataMapper,
      airConditioningDataMapper,
      lhsFrontTyremapper,
      rhsFrontTyremapper,
      lhsRearTyremapper,
      spareTypremapper,
      rhsRearTyremapper,
      exteriorComments,
      isLoading: false,
      additionalImagesMapper,
      odometerComments,
      interiorElecctricalsMapper,
      rejectionRemarkMapper,
      imagequalityParam: imageQuality,
      imageHostUrl: dynamicImageHostUrl,
      reInspectionReason: !!reInspectionReason ? reInspectionReason.reason : '',
      vahanData,
      isDocumentPopup: isPopupAllowed,
      isSpecialFieldEditable,
      c2cEligibility,
      workflowId
    };
  } catch (e) {
    console.log(e);
  }
};

const GetOldValueObject = (field, changedField, key) => {
  let newElement = Object.assign({}, changedField);
  let keyExist = true;
  if (!!newElement && !!newElement.subParts) {
    let subPartKeys = Object.keys(newElement.subParts);
    if (!!subPartKeys && subPartKeys.length > 0) {
      if (subPartKeys.indexOf(key) < 0) {
        keyExist = false;
      }
    }
  }
  if (!keyExist) {
    if (newElement.hasOwnProperty('subParts')) {
      delete newElement.subParts;
    }
  }
  return newElement;
};
const getErrors = (fieldObject, key) => {
  let field = fieldObject[key];
  let updateFieldValues = '';
  if (!!field && !!field.additionalInfo && field.additionalInfo.length > 0) {
    field.additionalInfo.forEach(element => {
      if (!!element.currentCondition && !!element.currentCondition.value) {
        updateFieldValues = !!updateFieldValues
          ? updateFieldValues + ', ' + element.currentCondition.value.toString()
          : element.currentCondition.value.toString();
      }
      if (!!element.workDone && !!element.workDone.value) {
        updateFieldValues = !!updateFieldValues
          ? updateFieldValues + ', ' + element.workDone.value.toString()
          : element.workDone.value.toString();
      }
    });
  }
  return updateFieldValues;
};
const getReInspectionUpdatedValues = (field, changedField) => {
  let updateFieldValues = '';
  if (!!field && !!field.additionalInfo && field.additionalInfo.length > 0) {
    field.additionalInfo.forEach(element => {
      if (!!element.currentCondition && !!element.currentCondition.value) {
        updateFieldValues = !!updateFieldValues
          ? updateFieldValues + ', ' + element.currentCondition.value.toString()
          : element.currentCondition.value.toString();
      }
      if (!!element.workDone && !!element.workDone.value) {
        updateFieldValues = !!updateFieldValues
          ? updateFieldValues + ', ' + element.workDone.value.toString()
          : element.workDone.value.toString();
      }
    });
  }
  if (!!changedField && !!changedField.subParts) {
    let finalArr = Object.keys(changedField.subParts);
    Object.keys(changedField.subParts).map(newelement => {
      if (!!field && !!field.subParts) {
        let newEntry = field.subParts[newelement];
        if (
          !!newEntry &&
          !!newEntry.additionalInfo &&
          newEntry.additionalInfo.length > 0
        ) {
          newEntry.additionalInfo.forEach(element => {
            if (!!element.currentCondition) {
              updateFieldValues = !!updateFieldValues
                ? updateFieldValues +
                  ', ' +
                  element.currentCondition.value.toString()
                : element.currentCondition.value.toString();
            }
            if (!!element.workDone) {
              updateFieldValues = !!updateFieldValues
                ? updateFieldValues + ', ' + element.workDone.value.toString()
                : element.workDone.value.toString();
            }
          });
        }
      }
    });
  }
  return updateFieldValues;
};
const getUpdateDifferences = changedField => {
  let updateFieldValues = '';
  if (
    !!changedField &&
    !!changedField.additionalInfo &&
    changedField.additionalInfo.length > 0
  ) {
    changedField.additionalInfo.forEach(element => {
      if (!!element.currentCondition && !!element.currentCondition.value) {
        updateFieldValues = !!updateFieldValues
          ? updateFieldValues + ', ' + element.currentCondition.value.toString()
          : element.currentCondition.value.toString();
      }
      if (!!element.workDone && !!element.workDone.value) {
        updateFieldValues = !!updateFieldValues
          ? updateFieldValues + ', ' + element.workDone.value.toString()
          : element.workDone.value.toString();
      }
    });
  }
  if (!!changedField && !!changedField.subParts) {
    let finalArr = Object.keys(changedField.subParts);
    // Object.keys(changedField.subParts).map(newelement => {
    finalArr.map(newelement => {
      // if (!!field && !!field.subParts) {
      let newEntry = changedField.subParts[newelement]; //field.subParts[newelement];
      if (
        !!newEntry &&
        !!newEntry.additionalInfo &&
        newEntry.additionalInfo.length > 0
      ) {
        newEntry.additionalInfo.forEach(element => {
          if (!!element.currentCondition && !!element.currentCondition.value) {
            updateFieldValues = !!updateFieldValues
              ? updateFieldValues +
                ', ' +
                element.currentCondition.value.toString()
              : element.currentCondition.value.toString();
          }
          if (!!element.workDone && !!element.workDone.value) {
            updateFieldValues = !!updateFieldValues
              ? updateFieldValues + ', ' + element.workDone.value.toString()
              : element.workDone.value.toString();
          }
        });
      }
      // }
    });
  }
  return updateFieldValues;
};

export const fetchReInspectionDetailSuccess = (
  state = INITIAL_STATE,
  { data },
) => {
  const {
    data: { inspectionData, inspectionEndTime, meta, appointmentId },
    regionDepartmentEmail,
  } = data;
  const currentAuctionVersion = getTimeStringFromSeconds(
    inspectionEndTime ? inspectionEndTime.sec : 0,
  );
  const { inspectionEndTime: lastSec } = data;
  const lastAuctionVersion = getTimeStringFromSeconds(
    lastSec ? lastSec.sec : 0,
  );
  const { noValidRiBid } = meta || {};
  let table = [];
  let index = 0;
  let reInspDiffSubPartArr = [];
  const makeModelVariantMapper = getMakeModelVariantMapper(data.data);
  try {
    Object.keys(inspectionData).map(item => {
      if (inspectionData[item].reinspDiff) {
        Object.keys(inspectionData[item].reinspDiff).map(diffItem => {
          let row = {};

          //oldValue these are basically the changed values which we got in reinspdiff
          const oldValue = inspectionData[item].reinspDiff[diffItem] || {};
          row.oldValue = oldValue; //GetOldValueObject(inspectionData[item][diffItem], inspectionData[item].reinspDiff[diffItem], diffItem);

          //these are the latest vaues of the fielld found in inspection field data
          const newValue = inspectionData[item][diffItem] || {
            title: diffItem,
            value: '',
          };
          row.newValue = newValue; //these are the latest vaues of the fielld found in reinsPensDiff
          row.updatedValues = getReInspectionUpdatedValues(newValue, oldValue);
          row.updateDifferences = getUpdateDifferences(oldValue);
          row.title = newValue.title;
          row.key = item;
          row.index = index;
          if (item === 'carDetails') {
            row = extendOldNewValuesSubPart(row, item, diffItem, 'new');
            row = extendOldNewValuesSubPart(row, item, diffItem, 'old');
          }
          if (item !== 'carDetails' && row.oldValue.subParts) {
            reInspDiffSubPartArr = Object.keys(row.oldValue.subParts);
          }
          if (row.oldValue.subParts) {
            let arr = [];
            Object.keys(row.oldValue.subParts).map(item => {
              let sub = {};
              sub.key = item;
              sub.errors = getErrors(row.oldValue.subParts, item);
              arr.push(item);
            });
            row.oldValue.sub_parts = arr;
          }
          if (row.newValue.subParts) {
            let arr = [];
            Object.keys(row.newValue.subParts).map(item => {
              let addSubPart = true;
              if (
                reInspDiffSubPartArr.length > 0 &&
                reInspDiffSubPartArr.indexOf(item) < 0
              ) {
                addSubPart = false;
              }
              let sub = {};
              sub.key = item;
              sub.errors = getErrors(row.newValue.subParts, item);
              if (addSubPart) {
                arr.push(item);
              }
            });
            row.newValue.sub_parts = arr;
          }
          ++index;
          table.push(row);
        });
      }
    });
    return {
      ...state,
      isLoading: false,
      riTableData: table,
      currentAuctionVersion,
      lastAuctionVersion,
      isLoading: false,
      regionDepartmentEmail,
      noValidRiBid,
      appointmentId,
      makeModelVariantMapper,
    };
  } catch (e) {
    console.log('Error in fetchReInspectionDetailSuccess method - ' + e);
    return { ...state, error: 'Expection', data: [], isLoading: false };
  }
};

export const fetchReInspectionDetailFailure = (
  state = INITIAL_STATE,
  { error },
) => {
  return { ...state, error: 'Expection', data: [], isLoading: false };
};

export const fetchInspectionDetailFailure = (
  state = INITIAL_STATE,
  { error },
) => {
  return { ...state, error: 'Expection', data: [], isLoading: false };
};
export const resetInspectionDetailByKey = (state = INITIAL_STATE, { data }) => {
  const { key, value } = data;
  return { ...state, [key]: value };
};

export const resetInspectionDetailDocumentByKey = (
  state = INITIAL_STATE,
  { data },
) => {
  let newState = { ...state };
  let docuMentstate = newState.documentsMapper;
  const { key, value } = data;
  let findData = docuMentstate.find(x => x.key === 'manufacturingDate');
  findData.value = '7 August';

  let index = docuMentstate.findIndex(x => x.key == 'manufacturingDate');
  docuMentstate[index] = findData;
  return { ...newState };
};

export const updateFieldRemarkHistory = (state = INITIAL_STATE, { data }) => {
  let {
    fieldInfo: { key, hashKey, mapper },
    remarkHistory,
  } = data;

  let updatedDocumentsMapper = state[mapper]?.map(docMap => {
    if (
      [
        'rcImagesMapper',
        'insuranceImageMapper',
        'exteriorTyresMapper',
        'interiorImagesMapper',
      ].includes(mapper)
    ) {
      if (docMap.hashKey === hashKey) {
        docMap.remarkHistory = remarkHistory;
        docMap.isCommentAdded = true;
        return docMap;
      } else {
        return docMap;
      }
    } else {
      if (mapper === 'additionalImagesMapper' && docMap.subPart === key) {
        docMap.remarkHistory = remarkHistory;
        docMap.isCommentAdded = true;
        return docMap;
      } else {
        if (docMap.key !== key && mapper !== 'mismatchMapper') {
          return docMap;
        } else {
          docMap.remarkHistory = remarkHistory;
          docMap.isCommentAdded = true;
          return docMap;
        }
      }
    }
  });

  return { ...state, [mapper]: updatedDocumentsMapper };
};

export const fetchRestrictedImagesSuccess = (
  state = INITIAL_STATE,
  { data },
) => {
  const { insuranceImages = [], rcImages = [] } = data;
  const preRcImages = rcImages
    .filter(item => item.status === 'pending')
    .map(item => {
      return {
        ...item,
        isPdf: item.url.split('.').pop() === 'pdf',
        type: 'RC',
        hashKey: 'inspectionData_carDetails_rcImages_',
        qcAction: '',
        imageTitle: `RC Images - ${item?.title}`,
      };
    });
  const preInsuranceImages = insuranceImages
    .filter(item => item.status === 'pending')
    .map(item => {
      return {
        ...item,
        isPdf: item.url.split('.').pop() === 'pdf',
        type: 'INS',
        hashKey: 'inspectionData_carDetails_insuranceImages_',
        qcAction: '',
        imageTitle: `Insurance Images - ${item?.title}`,
      };
    });
  return {
    ...state,
    preInspection: {
      preRcImages,
      preInsuranceImages,
      error: null,
    },
  };
};

export const fetchPreInspectionDataSuccess = (
  state = INITIAL_STATE,
  { data },
) => {
  const { insuranceImages = [], rcImages = [] } = data;
  const preRcImages = rcImages
    .filter(item => item.status === 'pending')
    .map(item => {
      return {
        ...item,
        isPdf: item.url.split('.').pop() === 'pdf',
        type: 'RC',
        hashKey: 'inspectionData_carDetails_rcImages_',
        qcAction: '',
        imageTitle: `RC Images - ${item?.title}`,
      };
    });
  const preInsuranceImages = insuranceImages
    .filter(item => item.status === 'pending')
    .map(item => {
      return {
        ...item,
        isPdf: item.url.split('.').pop() === 'pdf',
        type: 'INS',
        hashKey: 'inspectionData_carDetails_insuranceImages_',
        qcAction: '',
        imageTitle: `Insurance Images - ${item?.title}`,
      };
    });
  return {
    ...state,
    preInspection: {
      preRcImages,
      preInsuranceImages,
      error: null,
    },
  };
};
export const fetchPreInspectionDataFailure = (
  state = INITIAL_STATE,
  { err },
) => {
  return {
    ...state,
    preInspection: {
      images: [],
      rcImageCount: 0,
      additionalImageCount: 0,
      error: { ...err },
    },
  };
};
export const fetchInspectionRestrictImagesSuccess = (
  state = INITIAL_STATE,
  { data },
) => {
  const { carDetails = {} } = state;
  const rcImagesMapper = getRcImagesMapper(data, true, carDetails);
  const insuranceImageMapper = getInsuranceImageMapper(data, true, carDetails);
  return {
    ...state,
    isRestrictedImage: true,
    rcImagesMapper,
    insuranceImageMapper,
  };
};

export const fetchImperfectionsByAppointmentSuccess = (
  state = INITIAL_STATE,
  { data }
) => {
    const { interiorDataMapper, externalDataMapper} = state;
    const interiorMapperWithImperfections = getImperfectionDataMapper(interiorDataMapper, data?.electricalsInterior || {});
    const externalMapperWithImperfections = getImperfectionDataMapper(externalDataMapper, data?.exteriorTyres || {});
    return {
      ...state,
      interiorDataMapper: interiorMapperWithImperfections,
      externalDataMapper: externalMapperWithImperfections
    }
}

export const HANDLERS = {
  [Types.FETCH_RESTRICT_PRE_INSPECTION_IMAGES_SUCCESS]: fetchPreInspectionDataSuccess,
  [Types.FETCH_INSPECTIONS_DETAIL_SUCCESS]: fetchInspectionDetailSuccess,
  [Types.FETCH_INSPECTIONS_DETAIL_FAILURE]: fetchInspectionDetailFailure,
  [Types.RESET_INSPECTIONS_DETAIL]: resetInspectionDetail,
  [Types.RESET_INSPECTION_DETAIL_BY_KEY]: resetInspectionDetailByKey,
  [Types.RESET_INSPECTION_DETAIL_DOCUMENT_BY_KEY]: resetInspectionDetailDocumentByKey,
  [Types.FETCH_RE_INSPECTIONS_DETAIL_SUCCESS]: fetchReInspectionDetailSuccess,
  [Types.FETCH_RE_INSPECTIONS_DETAIL_FAILURE]: fetchReInspectionDetailFailure,
  [Types.UPDATE_FIELD_REMARK_HISTORY]: updateFieldRemarkHistory,
  [Types.FETCH_PRE_INSPECTION_DATA_SUCCESS]: fetchPreInspectionDataSuccess,
  [Types.FETCH_PRE_INSPECTION_DATA_FAILURE]: fetchPreInspectionDataFailure,
  [Types.FETCH_INSPECTION_DATA_START]: fetchInspectionDataStart,
  [Types.FETCH_INSPECTION_DATA_END]: fetchInspectionDetailEnd,
  [Types.FETCH_RESTRICT_INSPECTION_IMAGES_SUCCESS]: fetchInspectionRestrictImagesSuccess,
  [Types.FETCH_IMPERFECTION_BY_APPOINTMENT_SUCCESS]: fetchImperfectionsByAppointmentSuccess
};

const extendOldNewValuesSubPartForRcAvailability = (
  rowObject,
  key,
  diffItem,
  valType,
) => {
  let newRowObject = Object.assign({}, rowObject);
  let rcAvailability = {}; // newRowObject.newValue;
  if (valType === 'new') {
    rcAvailability = !!newRowObject.newValue ? newRowObject.newValue : {};
  } else {
    rcAvailability = !!newRowObject.oldValue ? newRowObject.oldValue : {};
  }
  const chassisNoOnRc =
    rcAvailability.subParts &&
    rcAvailability.subParts.rcCondition &&
    rcAvailability.subParts.rcCondition.subParts &&
    rcAvailability.subParts.rcCondition.subParts.chassisNumberOnRC
      ? rcAvailability.subParts.rcCondition.subParts.chassisNumberOnRC
      : null;
  let mismatchInRc =
    rcAvailability.subParts &&
    rcAvailability.subParts.rcCondition &&
    rcAvailability.subParts.rcCondition.subParts &&
    rcAvailability.subParts.rcCondition.subParts.mismatchInRC
      ? rcAvailability.subParts.rcCondition.subParts.mismatchInRC
      : null;

  if (valType === 'new') {
    if (newRowObject.newValue && newRowObject.newValue.subParts) {
      if (chassisNoOnRc != null) {
        newRowObject.newValue.subParts['chassisNoOnRc'] = chassisNoOnRc;
      }
      if (mismatchInRc != null) {
        newRowObject.newValue.subParts['mismatchInRc'] = mismatchInRc;
      }
    }
  } else {
    if (newRowObject.oldValue && newRowObject.oldValue.subParts) {
      if (chassisNoOnRc != null) {
        newRowObject.oldValue.subParts['chassisNoOnRc'] = chassisNoOnRc;
      }
      if (mismatchInRc != null) {
        newRowObject.oldValue.subParts['mismatchInRc'] = mismatchInRc;
      }
    }
  }
  return newRowObject;
};
const extendOldNewValuesSubPartFor_RegistrationState = (
  rowObject,
  key,
  diffItem,
  valType,
) => {
  let newRowObject = Object.assign({}, rowObject);
  let registrationState = {}; // newRowObject.newValue;
  if (valType === 'new') {
    registrationState = !!newRowObject.newValue ? newRowObject.newValue : {};
  } else {
    registrationState = !!newRowObject.oldValue ? newRowObject.oldValue : {};
  }
  const rto =
    registrationState.subParts && registrationState.subParts.rto
      ? registrationState.subParts.rto
      : null;

  if (valType === 'new') {
    if (newRowObject.newValue && newRowObject.newValue.subParts) {
      if (rto != null) {
        newRowObject.newValue.subParts['rto'] = rto;
      }
    }
  } else {
    if (newRowObject.oldValue && newRowObject.oldValue.subParts) {
      if (rto != null) {
        newRowObject.oldValue.subParts['rto'] = rto;
      }
    }
  }
  return newRowObject;
};
const extendOldNewValuesSubPartFor_InsuranceType = (
  rowObject,
  key,
  diffItem,
  valType,
) => {
  let newRowObject = Object.assign({}, rowObject);
  let insuranceType = {}; // newRowObject.newValue;
  if (valType === 'new') {
    insuranceType = !!newRowObject.newValue ? newRowObject.newValue : {};
  } else {
    insuranceType = !!newRowObject.oldValue ? newRowObject.oldValue : {};
  }
  const insuranceExpiry =
    insuranceType.subParts && insuranceType.subParts.insuranceExpiry
      ? insuranceType.subParts.insuranceExpiry
      : null;
  const noClaimBonus =
    insuranceType.subParts && insuranceType.subParts.noClaimBonus
      ? insuranceType.subParts.noClaimBonus
      : null;
  let noClaimBonusDep =
    insuranceType.subParts &&
    insuranceType.subParts.noClaimBonus &&
    insuranceType.subParts.noClaimBonus.subParts &&
    insuranceType.subParts.noClaimBonus.subParts.noClaimBonusPercentage
      ? insuranceType.subParts.noClaimBonus.subParts.noClaimBonusPercentage
      : null;

  if (valType === 'new') {
    if (newRowObject.newValue && newRowObject.newValue.subParts) {
      if (insuranceExpiry != null) {
        newRowObject.newValue.subParts['insuranceExpiry'] = insuranceExpiry;
      }
      if (noClaimBonus != null) {
        newRowObject.newValue.subParts['noClaimBonus'] = noClaimBonus;
      }
      if (noClaimBonusDep != null) {
        newRowObject.newValue.subParts['noClaimBonuspercent'] = noClaimBonusDep;
      }
    }
  } else {
    if (newRowObject.oldValue && newRowObject.oldValue.subParts) {
      if (insuranceExpiry != null) {
        newRowObject.oldValue.subParts['insuranceExpiry'] = insuranceExpiry;
      }
      if (noClaimBonus != null) {
        newRowObject.oldValue.subParts['mismatchInRc'] = noClaimBonus;
      }
      if (noClaimBonusDep != null) {
        newRowObject.oldValue.subParts['noClaimBonuspercent'] = noClaimBonusDep;
      }
    }
  }
  return newRowObject;
};
const extendOldNewValuesSubPartFor_RtoNocIssuedType = (
  rowObject,
  key,
  diffItem,
  valType,
) => {
  let newRowObject = Object.assign({}, rowObject);
  let rtoNoIssued = {}; // newRowObject.newValue;
  if (valType === 'new') {
    rtoNoIssued = !!newRowObject.newValue ? newRowObject.newValue : {};
  } else {
    rtoNoIssued = !!newRowObject.oldValue ? newRowObject.oldValue : {};
  }

  const rtoNoIssuedSubparts = rtoNoIssued.subParts;
  const rtoNocAvailable =
    rtoNoIssued && rtoNoIssuedSubparts && rtoNoIssuedSubparts.rtoNocAvailable
      ? rtoNoIssuedSubparts.rtoNocAvailable
      : null;
  const rtoNmcAvailable =
    rtoNoIssued && rtoNoIssuedSubparts && rtoNoIssuedSubparts.rtoNmcAvailable
      ? rtoNoIssuedSubparts.rtoNmcAvailable
      : null;

  const rtoNocAvailableSubparts =
    rtoNocAvailable && rtoNocAvailable.subParts
      ? rtoNocAvailable.subParts
      : null;

  const rtoNocIssuedFor =
    rtoNoIssued && rtoNoIssuedSubparts && rtoNoIssuedSubparts.rtoNocIssuedFor
      ? rtoNoIssuedSubparts.rtoNocIssuedFor
      : null;
  const rtoNocIssuedDate =
    rtoNoIssued && rtoNoIssuedSubparts && rtoNoIssuedSubparts.rtoNocIssuedDate
      ? rtoNoIssuedSubparts.rtoNocIssuedDate
      : null;

  if (valType === 'new') {
    if (newRowObject.newValue && newRowObject.newValue.subParts) {
      if (rtoNocAvailable != null) {
        newRowObject.newValue.subParts['rtoNocAvailable'] = rtoNocAvailable;
      }
      if (rtoNmcAvailable != null) {
        newRowObject.newValue.subParts['rtoNmcAvailable'] = rtoNmcAvailable;
      }
      if (rtoNocIssuedFor != null) {
        newRowObject.newValue.subParts['rtoNocIssuedFor'] = rtoNocIssuedFor;
      }

      if (rtoNocIssuedDate != null) {
        newRowObject.newValue.subParts['rtoNocIssuedDate'] = rtoNocIssuedDate;
      }
    }
  } else {
    if (newRowObject.oldValue && newRowObject.oldValue.subParts) {
      if (rtoNocAvailable != null) {
        newRowObject.oldValue.subParts['rtoNocAvailable'] = rtoNocAvailable;
      }
      if (rtoNmcAvailable != null) {
        newRowObject.oldValue.subParts['rtoNmcAvailable'] = rtoNmcAvailable;
      }
      if (rtoNocIssuedFor != null) {
        newRowObject.oldValue.subParts['rtoNocIssuedFor'] = rtoNocIssuedFor;
      }

      if (rtoNocIssuedDate != null) {
        newRowObject.oldValue.subParts['rtoNocIssuedDate'] = rtoNocIssuedDate;
      }
    }
  }
  return newRowObject;
};
const extendOldNewValuesSubPartFor_RoadTaxPaid = (
  rowObject,
  key,
  diffItem,
  valType,
) => {
  let newRowObject = Object.assign({}, rowObject);
  let roadTaxPaid = {}; // newRowObject.newValue;
  if (valType === 'new') {
    roadTaxPaid = !!newRowObject.newValue ? newRowObject.newValue : {};
  } else {
    roadTaxPaid = !!newRowObject.oldValue ? newRowObject.oldValue : {};
  }
  const roadTaxDateValidity =
    roadTaxPaid.subParts && roadTaxPaid.subParts.roadTaxDateValidity
      ? roadTaxPaid.subParts.roadTaxDateValidity
      : null;

  if (valType === 'new') {
    if (newRowObject.newValue && newRowObject.newValue.subParts) {
      if (roadTaxDateValidity != null) {
        newRowObject.newValue.subParts[
          'rroadTaxDateValidityto'
        ] = roadTaxDateValidity;
      }
    }
  } else {
    if (newRowObject.oldValue && newRowObject.oldValue.subParts) {
      if (roadTaxDateValidity != null) {
        newRowObject.oldValue.subParts[
          'roadTaxDateValidity'
        ] = roadTaxDateValidity;
      }
    }
  }
  return newRowObject;
};
const extendOldNewValuesSubPartFor_UnderHypoThetication = (
  rowObject,
  key,
  diffItem,
  valType,
) => {
  let newRowObject = Object.assign({}, rowObject);
  let isUnderHypothecation = {}; // newRowObject.newValue;
  if (valType === 'new') {
    isUnderHypothecation = !!newRowObject.newValue ? newRowObject.newValue : {};
  } else {
    isUnderHypothecation = !!newRowObject.oldValue ? newRowObject.oldValue : {};
  }

  const bankNocStatus =
    isUnderHypothecation.subParts && isUnderHypothecation.subParts.bankNocStatus
      ? isUnderHypothecation.subParts.bankNocStatus
      : null;
  const bankNocExpiryDate =
    bankNocStatus &&
    bankNocStatus.subParts &&
    bankNocStatus.subParts.bankNocExpiryDate
      ? bankNocStatus.subParts.bankNocExpiryDate.value
      : '';

  if (valType === 'new') {
    if (newRowObject.newValue && newRowObject.newValue.subParts) {
      if (bankNocStatus != null) {
        newRowObject.newValue.subParts['bankNocStatus'] = bankNocStatus;
      }
      if (bankNocExpiryDate != null) {
        newRowObject.newValue.subParts['bankNocExpiryDate'] = bankNocExpiryDate;
      }
    }
  } else {
    if (newRowObject.oldValue && newRowObject.oldValue.subParts) {
      if (bankNocStatus != null) {
        newRowObject.oldValue.subParts['bankNocStatus'] = bankNocStatus;
      }
      if (bankNocExpiryDate != null) {
        newRowObject.oldValue.subParts['bankNocExpiryDate'] = bankNocExpiryDate;
      }
    }
  }
  return newRowObject;
};
const extendOldNewValuesSubPart = (rowObject, key, diffItem, valType) => {
  let newRowObject = Object.assign({}, rowObject);
  if (diffItem === 'rcAvailability') {
    newRowObject = extendOldNewValuesSubPartForRcAvailability(
      rowObject,
      key,
      diffItem,
      valType,
    );
  } else if (diffItem === 'registrationState') {
    newRowObject = extendOldNewValuesSubPartFor_RegistrationState(
      rowObject,
      key,
      diffItem,
      valType,
    );
  } else if (diffItem === 'insuranceType') {
    newRowObject = extendOldNewValuesSubPartFor_InsuranceType(
      rowObject,
      key,
      diffItem,
      valType,
    );
  } else if (diffItem === 'rtoNocIssued') {
    newRowObject = extendOldNewValuesSubPartFor_RtoNocIssuedType(
      rowObject,
      key,
      diffItem,
      valType,
    );
  } else if (diffItem === 'roadTaxPaid') {
    newRowObject = extendOldNewValuesSubPartFor_RoadTaxPaid(
      rowObject,
      key,
      diffItem,
      valType,
    );
  } else if (diffItem === 'isUnderHypothecation') {
    newRowObject = extendOldNewValuesSubPartFor_UnderHypoThetication(
      rowObject,
      key,
      diffItem,
      valType,
    );
  }

  return newRowObject;
};

export default createReducer(INITIAL_STATE, HANDLERS);
