import { API_ENDPOINTS } from './api-constants';
import { convertObjectToParams } from '../utils/helper';

export default api => {
  const getGsUnflaggingList = params => {
    return api.get(
      `${API_ENDPOINTS.GS_DEALER_DATA}${convertObjectToParams(params)}&utm_source=qc`,
    );
  };

  const getGsUnflaggingDetails = (appointmentId, leadToken, params) => {
    return api.get(
      `${API_ENDPOINTS.GS_DEALER_DATA}/${appointmentId}?leadToken=${leadToken}&utm_source=qc`,
    );
  };

  const gsUnflaggingAction = (appointmentId, data) => {
    return api.patch(
      `${API_ENDPOINTS.GS_DEALER_DATA}/${appointmentId}?utm_source=qc`,
      data,
    );
  };

  const getAllDispositions = () => {
    return api.get(`${API_ENDPOINTS.FETCH_ALL_DISPOSITION}?utm_source=qc`, {});
  };

  return {
    getGsUnflaggingList,
    getGsUnflaggingDetails,
    gsUnflaggingAction,
    getAllDispositions,
  };
};
