import axios from 'axios';
import {
  apiUrl,
  apiAuthKey,
  apiHeader,
  bikeApiUrl,
} from '../utils/url-constants';
import { ERROR_CODES } from './api-constants';
import { ROLE_KEYS, LOCAL_STORAGE_KEYS } from '../utils/app-constants';
import { getFromLocalStorage, isUserRoleBIkeQc } from '../utils/helper';
import { getSessionToken } from '@descope/react-sdk';
const DEBUG = process.env.NODE_ENV === 'development';
const BASE_API_URL = apiUrl();

/* Create axios instance */
const api = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  config => {
    let isBikeQc = isUserRoleBIkeQc();
    let baseUrl = getBaseUrl(config.url);
    if (config && config?.customBaseURL) {
      baseUrl = config.customBaseURL;
    }
    config.baseURL = baseUrl;
    const authKeyHeader = apiHeader(
      isHeaderForBike(isBikeQc, config.url),
      getSessionToken(),
    );
    config.headers['Authorization'] = authKeyHeader; //"Bearer 6b0f2163-1953-4f89-9dc4-8353416e7152";//authKeyHeader;//
    /** In dev, intercepts request and logs it into console for dev */
    // if (DEBUG) { console.info("✉️ ", config); }
    return config;
  },
  error => {
    // if (DEBUG) { console.error("✉️ ", error); }
    return Promise.reject(error);
  },
);

const isHeaderForBike = (isBikeQc, url) => {
  if (
    isBikeQc &&
    (url.match(/oauth/i) ||
      url.match(/qcLogout/i) ||
      url.match(/bike-image/i) ||
      url.match(/bikeMake/i) ||
      url.match(/bikeModel/i) ||
      url.match(/bikeVariant/i) ||
      url.match(/state/i) ||
      url.match(/rto/i))
  ) {
    return !isBikeQc;
  } else {
    return isBikeQc;
  }
};
const getBaseUrl = url => {
  let isBikeQc = isUserRoleBIkeQc();
  let baseURL = !isBikeQc ? apiUrl() : bikeApiUrl();
  if (
    url.match(/oauth/i) ||
    url.match(/qcLogout/i) ||
    url.match(/bike-image/i) ||
    url.match(/bikeMake/i) ||
    url.match(/bikeModel/i) ||
    url.match(/bikeVariant/i) ||
    url.match(/state/i) ||
    url.match(/rto/i)
  ) {
    baseURL = apiUrl();
  }
  return baseURL;
};
/**
 * Passes response.data to services.
 * In dev, intercepts response and logs it into console for dev
 */
api.interceptors.response.use(
  response => {
    // if (DEBUG) { console.info('📩 ', response); }
    return response;
  },
  error => {
    if (error.response && error.response.data) {
      if (DEBUG) {
        console.error('Error: ', error.response.data);
      }
      if (
        error.response.status === ERROR_CODES.UNAUTHORISED &&
        !window.location.pathname.includes('login')
      ) {
        localStorage.clear();
        window.location = `${window.origin}/login`;
      }
      return Promise.reject(error.response.data);
    }
    if (DEBUG) {
      console.error('📩 ', error);
    }
    return Promise.reject(error.message);
  },
);

export default api;
