import React, {useState} from "react";
import { Tooltip } from "@material-ui/core";
import { RESET_REDUCER_KEYS } from "../../utils/app-constants";
// import styles from "../inspection-insurance/inspection-insurance.module.css";
import styles from "./inspection-interior.module.css";
import Checkbox from "../shared/checkbox/";
import RightSign from "../../components/images/rightSign.svg";
import InfoIcon from "../../components/images/infoIcon.svg";
import { getEditableComponent } from "../../utils/helper";
import FuelLevel from '../fuel-level';
import Change from "../../components/change";
import { useDispatch } from "react-redux";
import Remark from "../shared/remark/Remark";

const InspectionInterior = ({
    document,
    resetInspectionDetailByKeyConnect,
    inspectionDetails,
    currentMapper,
    section
}) => {
    const dispatch = useDispatch()
    const [isRemarkHistoryOpen, setIsRemarkHistoryOpen] = useState(false);
    const { text, checked, value, key, newValue, isEditable, prevValue, mapper: _mapper, isMatchWithJATO = "",  valueByJATO = ""} = document;
    const currentMapperValue = inspectionDetails[currentMapper];
    const { additionalImagesMapper } = inspectionDetails;

    const additionalImagesLink = {
        alloyWheels: "Alloy Wheel",
        sunroof: "Sunroof",
        leatherSeats: "Leather Seat",
        fabricSeats: "Fabric Seat"
    };

    const updateAdditonalImageMapper = (title, checked) => {
        const index = additionalImagesMapper.findIndex(item => item.title.toLowerCase().match(title.toLowerCase()));
        if (index > -1) {
            additionalImagesMapper[index].checked = checked;
            resetInspectionDetailByKeyConnect("additionalImagesMapper", additionalImagesMapper);
        }

    };

    const formatValue = (value, type) => {
        if (currentMapper === "interiorElecctricalsMapper") {
            return "";
        }
        else {
            if (value) {
                if (value.match(/na/i) || value == "N/A" || value.match(/0/i) || value == 0) {
                    return "";
                }
                else {
                    return type === "border" ? styles.borderHighlight : styles.strong;
                }
            }
        }
    };

    const onCheckboxHandler = (key) => {
        let mapper = [...currentMapperValue];
        const index = mapper.findIndex(item => item.key === key);
        mapper[index].checked = !mapper[index].checked;
        if (mapper[index].additionalImageKey) {
            updateAdditonalImageMapper(additionalImagesLink[key], mapper[index].checked);
        }
        resetInspectionDetailByKeyConnect(currentMapper, mapper);
        setIsRemarkHistoryOpen(!checked);
    };

    const getInfoIcon = () => {
        if(isMatchWithJATO === true) {
            return <img src={RightSign} alt="right Sign" className={styles.vaahanIcon} />
        } else if (isMatchWithJATO === false ) {
            return <img src={InfoIcon} alt="info mismatch Sign" />
        }
    }

    return (
        <>
        <div className={`${styles.documentInfo} ${formatValue(value, "border")}`}>
            <div className={styles.checkboxlabel}>
                <Checkbox
                    checkedStatus={checked}
                    onChangeCallback={() => onCheckboxHandler(key)}
                    name={`${currentMapper}_${key}`}
                    id={`${currentMapper}_${key}`}
                />
                <label className={`${styles.lableText} ${formatValue(value) ? styles.strong : ""} `}>{text}</label>
            </div>
            {
                checked && document.key === "fuelLevel" && (
                    <FuelLevel 
                        fuelValue={document.value} 
                        currentMapper={currentMapper} 
                    />
                )
            }
            <div className={styles.dataWrapper} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}>
                {valueByJATO && (
                    <Tooltip title={`JATO value: ${valueByJATO}`} placement="left" classes={{
                        tooltip: styles.tooltipCustom,
                        arrow: styles.arrowCustom,
                        popper: styles.popperCustom, // optional, if you want to style the popper container
                      }}>
                        {getInfoIcon()}
                    </Tooltip>
                )}
                {/*{*/}
                {/*    checked && isEditable ? getEditableComponent(key, document, resetInspectionDetailByKeyConnect) : newValue ? newValue : value*/}
                {/*}*/}
                {
                    checked && document.key === "fuelLevel" ? null : <span className={formatValue(value)}>{checked && document.newValue || value}</span>
                }
                
                {
                    checked && newValue ? <Change value={value} /> : ""
                }
                {
                    prevValue ? <div className={styles.newValue}>PE:{`${prevValue}`}</div> : ""
                }
                {!!document?.remarkHistory?.length && (
                    <span
                        className={"remarkHistoryBtn ml-10"}
                        onClick={() => setIsRemarkHistoryOpen(!isRemarkHistoryOpen)}
                    >
                        Remark History
                    </span>
                )}
            </div>
        </div>
        <Remark
            document={document}
            section={section}
            showRemarkInput={document.checked}
            isRemarkHistoryOpen={isRemarkHistoryOpen}
        />
    </>
    );
};

export default InspectionInterior;
