import Types from './types';
import { InspectionService } from '../../service';
// import { getInspectionMocData } from "../../utils/mocInsApi";

const fetchInspectionDetail = (
  carId,
  version,
  inspectionStatus,
) => dispatch => {
  if (version > -1 || version === 'current') {
    return InspectionService.getInspectionVersionDetail(
      carId,
      version,
      inspectionStatus,
    )
      .then(response => {
        const {
          data: {
            detail: { data },
          },
        } = response;
        // const { detail } = data;
        dispatch(fetchInspectionDetailSuccess(data));
        return response;
      })
      .catch(error => dispatch(fetchInspectionDetailFailure(error)));
  } else {
    return InspectionService.getInspectionDetail(carId)
      .then(response => {
        const {
          data: { detail },
        } = response;
        const { appointmentId, delayAuction: { isDocUploadedByCX = 0 } = {} } =
          detail || {};
        dispatch(fetchInspectionDetailSuccess(detail));
        if (!!isDocUploadedByCX) {
          dispatch(fetchInspectionDataStart());
          InspectionService.fetchPreInspectionData(appointmentId)
            .then(resp => {
              const {
                data: { detail },
              } = resp;
              dispatch(fetchPreInspectionDataSuccess(detail));
            })
            .catch(err => {
              dispatch(fetchPreInspectionDataFailure(err));
            })
            .finally(() => {
              dispatch(fetchInspectionDataEnd());
            });
        }
        return response;
      })
      .catch(error => dispatch(fetchInspectionDetailFailure(error)))
      .finally(() => {
        // dispatch(fetchInspectionDataEnd());
      });
  }
};

const fetchRestrictedPreInsImages = appointmentId => dispatch => {
  dispatch(fetchInspectionDataStart());
  InspectionService.fetchPreInspectionData(appointmentId)
    .then(resp => {
      const {
        data: { detail },
      } = resp;
      dispatch(fetchRestrictPreInspectionDataSuccess(detail));
    })
    .finally(() => {
      dispatch(fetchInspectionDataEnd());
    });
};
const fetchInspectionRestrictImages = (appointmentId, leadToken) => dispatch => {
  InspectionService.getInspectionRestrictImages(appointmentId, leadToken).then(resp => {
    const {
      data: { detail },
    } = resp;
    dispatch(fetchInspectionRestrictImagesSuccess(detail));
  }).catch(error => console.error(error));
};
const fetchReInspection = (carId, selectedVersion) => dispatch => {
  return InspectionService.getReinspection(carId, selectedVersion)
    .then(response => {
      const {
        data: { detail },
      } = response;
      // const { detail } = data;
      dispatch(fetchReInspectionDetailSuccess(detail));
      return response;
    })
    .catch(error => dispatch(fetchReInspectionDetailFailure(error)));
};

const getRejectionRemark = (params, mapper) => dispatch => {
  return InspectionService.getRejectionRemark(params)
    .then(response => {
      let { detail } = response.data;
      dispatch(
        updateFieldRemarkHistory(
          {
            key: params.subPart || params.part,
            hashKey: params.hashKey,
            mapper,
          },
          detail,
        ),
      );
    })
    .catch(error => console.error(error));
};

const saveRejectionRemark = (data, callback) => dispatch => {
  return InspectionService.saveRejectionRemark(data)
    .then(response => {
      const { detail } = response.data;
    })
    .catch(error =>
      alert(error.detail || 'Some error occured while saving the remark'),
    )
    .finally(() => callback());
};

const fetchImperfectionsByAppointment = (appointmentId) => dispatch => {
  return InspectionService.fetchImperfectionsByAppointment(appointmentId)
    .then(response => {
      dispatch(fetchImperfectionsByAppointmentSuccess(response?.data))
    })
    .catch(error => {
      dispatch(fetchImperfectionsByAppointmentFailure(error))
    })
}

const fetchImperfectionsByAppointmentSuccess = data => ({
  type: Types.FETCH_IMPERFECTION_BY_APPOINTMENT_SUCCESS,
  data
});

const fetchImperfectionsByAppointmentFailure = data => ({
  type: Types.FETCH_IMPERFECTION_BY_APPOINTMENT_FAILURE,
  data
});

const fetchInspectionRestrictImagesSuccess = data => ({
  type: Types.FETCH_RESTRICT_INSPECTION_IMAGES_SUCCESS,
  data,
});

const fetchInspectionDetailSuccess = data => ({
  type: Types.FETCH_INSPECTIONS_DETAIL_SUCCESS,
  data,
});
const fetchInspectionDetailFailure = data => ({
  type: Types.FETCH_INSPECTIONS_DETAIL_FAILURE,
  data,
});

// FETCH_RE_INSPECTIONS_DETAIL_SUCCESS
// FETCH_RE_INSPECTIONS_DETAIL_FAILURE

const fetchReInspectionDetailSuccess = data => ({
  type: Types.FETCH_RE_INSPECTIONS_DETAIL_SUCCESS,
  data,
});
const fetchReInspectionDetailFailure = data => ({
  type: Types.FETCH_RE_INSPECTIONS_DETAIL_FAILURE,
  data,
});

const fetchRestrictPreInspectionDataSuccess = data => ({
  type: Types.FETCH_RESTRICT_PRE_INSPECTION_IMAGES_SUCCESS,
  data,
});

const fetchPreInspectionDataSuccess = data => ({
  type: Types.FETCH_PRE_INSPECTION_DATA_SUCCESS,
  data,
});
const fetchPreInspectionDataFailure = data => ({
  type: Types.FETCH_PRE_INSPECTION_DATA_FAILURE,
  data,
});

const fetchInspectionDataStart = () => ({
  type: Types.FETCH_INSPECTION_DATA_START,
});
const fetchInspectionDataEnd = () => ({
  type: Types.FETCH_INSPECTION_DATA_END,
});

const resetInspectionDetailByKey = (key, value) => ({
  type: Types.RESET_INSPECTION_DETAIL_BY_KEY,
  data: { key, value },
});
const resetInspectionDetailDocumentByKey = (key, value) => ({
  type: Types.RESET_INSPECTION_DETAIL_DOCUMENT_BY_KEY,
  data: { key, value },
});

const resetInspectionDetail = () => ({
  type: Types.RESET_INSPECTIONS_DETAIL,
});

const updateFieldRemarkHistory = (fieldInfo, remarkHistory) => ({
  type: Types.UPDATE_FIELD_REMARK_HISTORY,
  data: { fieldInfo, remarkHistory },
});

export {
  fetchRestrictedPreInsImages,
  fetchInspectionRestrictImages,
  fetchInspectionDetail,
  resetInspectionDetailByKey,
  resetInspectionDetail,
  resetInspectionDetailDocumentByKey,
  fetchReInspection,
  getRejectionRemark,
  saveRejectionRemark,
  fetchImperfectionsByAppointment
};
